@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Work Sans", sans-serif !important;
}

a {
  color: #7345B3 !important;
  text-decoration: none !important;
}

a:hover {
  text-decoration: underline !important;
}

/* .background_Image {
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat !important;
  background-position: center;
  background-size: cover !important;
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0)), url("./assets/images/bg.svg");
} */

.background_color {
  background-color: #f8f8f8 !important;
}

/*--------------------- 
        Scrollbar css 
----------------------------------*/
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  position: absolute;
  display: none;
  scrollbar-gutter: stable both-edges;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #e4e4e4;
  border-radius: 10px;
  border: 2px solid transparent;
}

/*--------------------- 
        Scrollbar css 
----------------------------------*/

.tooltip_custom {
  z-index: 9999999 !important;
}

/* .scrollable-content {
  margin-left: auto;
  margin-right: auto;
}
.scrollable-content:hover,
.scrollable-content:active,
.scrollable-content:focus {
  overflow-y: scroll;
} */

.background_gradient {
  background: linear-gradient(#0009, #0009);
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}


/*--------------------- 
        MUI css 
----------------------------------*/
.sidebar_text span {
  font-size: 18px !important;
  line-height: 1 !important;
}

.active_sidebar {
  background: #fef9f8 !important;
}

.card_container {
  background: #fff;
  border-radius: 12px;
  padding: 40px;
}

.border_card {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  /* background: #f8f8f8 !important; */
}

.disable_button .Mui-disabled {
  background-color: #7345B3 !important;
  color: #fff !important;
}

.category_table_row .MuiTableCell-root {
  font-weight: 600 !important;
}

.category_table_row {
  background: #7345B320 !important;
}

.specification_table .MuiTableRow-root:hover {
  box-shadow: unset !important;
  background: unset !important;
}

.custome_tab_menu {
  background: #fff;
  border-radius: 10px;
  padding: 7px;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

.table-hover {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tablebodyscrooling {
  max-height: 550px !important;
  height: 100% !important;
  min-height: fit-content !important;
  overflow-y: scroll;

}

.table-hover .MuiSelect-select {
  padding: 7px 20px !important;
  padding-right: 32px !important
}

.custome_tab_menu div {
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width:600px) {
  .sidebar_text span {
    font-size: 16px !important;
  }
}

.box_shadow {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 10px;
}

.purchase_order_table .MuiTableCell-root {
  padding: 10px 15px !important;
}

.purchase_order_table .MuiTableRow-root:hover {
  box-shadow: unset !important;
  background: unset !important;
}



.price_input_main {}

.input_select fieldset {
  /* border: unset !important; */
  /* border-right: 1px solid rgba(0, 0, 0, 0.12) !important; */
  /* border-radius: unset !important; */
}

.input_select .MuiSvgIcon-root {
  right: 3px !important;
  width: 0.8em !important;
}

.input_select .MuiSelect-select {
  padding: 5px 28px 5px 10px !important;
}

.select_input input {
  padding: 10px 20px 10px 60px !important;
}

/*--------------------- 
        MUI css 
----------------------------------*/

/*--------------------- 
        DateRange css 
----------------------------------*/
.date_range {
  width: max-content !important;
}

.date_range button {
  background: #fff;
  color: #7345B3;
  font-weight: 400;
  border: unset !important;
  border-radius: 10px;
  padding: 9px 10px;
  text-align: start;
  font-size: 15px;
  width: 100%;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
}

.date_range_width button {
  width: unset !important;
  background: #7345B3 !important;
  color: #fff !important;
}

.daterangepicker .ranges li.active {
  background-color: #7345B3 !important;
}

.daterangepicker.opensright:before,
.daterangepicker.opensright:after {
  right: 20px !important;
  left: unset !important;
  display: none !important;
}

.show-calendar {
  right: 20px !important;
  overflow: scroll;
  height: 303px;
}

.applyBtn {
  background: #7345B3 !important;
  border: 1px solid #7345B3 !important;
}

.daterangepicker td.in-range {
  background-color: #7345B320 !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #7345B3 !important;
}

.date_range_icon {
  padding: 4px;
}

.date_range_icon svg {
  font-size: 22px;
}

@media (max-width:600px) {
  .daterangepicker {
    right: 20px !important;
  }

  .date_range button {
    padding: 13px 10px;
    font-size: 14px;
  }

  .date_range_icon {
    padding: 3px;
    display: none !important;
  }

  .date_range_icon svg {
    font-size: 18px;
  }
}





/*--------------------- 
        DateRange css 
----------------------------------*/

/*--------------------- 
        Radix UI css 
----------------------------------*/
.contactDropDown,
.profileDropDownTrigger,
.notificationDropDownTrigger,
.chatDropDown {
  background: transparent;
  display: flex;
  border: unset !important;
  outline: unset !important;
}

[data-radix-popper-content-wrapper] {
  top: 9px !important;
  font-weight: 400;
}

.contactDropDownContent,
.profileDropDownContent,
.notificationDropDownContent,
.chatDropDownContent {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.12);
  z-index: 1000;
  padding: 5px;
}

.contactDropDownMenu,
.profileDropDownMenu,
.notificationDropDownMenu,
.chatDropDownMenu {
  border: unset !important;
  outline: unset !important;
  font-size: 14px !important;
  cursor: pointer;
}

.profileDropDownMenu {
  padding: 12px
}

.profileDropDownMenu:hover {
  background: #7345B320;
}

.notificationDropDownMenu {
  padding: 10px;
}

.profileDropDownContent {
  padding: 8px !important;
}

.contactDropDownMenu,
.chatDropDownMenu {
  padding: 7px 10px;
}

.chatDropDownMenu:hover {
  background: #7345B320;
}

.contactDropDown .MuiButtonBase-root {
  width: 100%;
}

.contactDropDownMenu:hover {
  background: #7345B320;
}

.profileDropDown [data-radix-popper-content-wrapper] {
  left: 53px !important;
  top: 47px !important;
  width: 250px !important;
}

.upload_product_modal .MuiDialog-paper {
  max-width: 100% !important;
}

/*--------------------- 
        Radix UI css 
----------------------------------*/

.example {
  width: 80%;
  height: min-content;
  stroke: #333;
  padding: 4%;
  /* border: 1px solid #eaeef5; */
}

.price_input_main,
.price_input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.price_input_adjustment {
  gap: 5px;
}

.price_input fieldset {}

.price_input input {
  width: 150px !important;
  padding: 7px 15px !important;
}

.price_input_main input {
  width: 95px !important;
  padding: 7px 15px !important;
}

.input_select {
  width: 50px !important;
}

.price_input p {
  margin-bottom: 0 !important;
}

.MuiFormHelperText-root {
  font-size: 10px !important;
}

.MuiInput-underline .MuiSelect-select {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  background: transparent !important;
}

.create_purchase_vendor .MuiAutocomplete-root,
.create_purchase_vendor .MuiBox-root {
  width: 100%;
  max-width: 250px;
}

.create_purchase_vendor .MuiInputBase-root {
  padding: 0 !important;
}
.chart-container {
  position: relative;
  width: 100%;  /* Make the width 100% of the parent */
  /* max-width: 600px;   */
  margin: 0 auto;  /* Center the chart */
  padding: 20px;  /* Add some padding */
}